import { createReducer, on } from "@ngrx/store";
import {
  resetInPageNavigation,
  setAudienceNavigation,
  setBaseHref,
  setCurrentBusinessUnit,
  setCurrentPageId,
  setCurrentPageTitle,
  setFilterByCategory,
  setInPageNavigation,
  setMainNavMobileOpen, setMainNavOpen,
  setPageType,
  setTrustPilot,
  setWindowLoaded,
  setYoutubeScriptLoaded
} from "./global.actions";

// this interface is here to avoid TS error when using a string to access object keys
// this error would have appeared in the text block when dynamically loading the content area layout
interface IObjectKeys {
  [key: string]: any;
}

export interface GlobalState extends IObjectKeys {
  baseHref: string;
  inPageNav: any;
  filterCategory: number | null;
  pageTitle: string;
  pageId: number | null;
  businessUnit: string;
  youtubeScriptLoaded: boolean;
  trustPilot: { scriptLoaded: boolean; blockId: string; trustPilotData: any };
  audienceNavigation: [any] | undefined;
  isMainNavMobileOpen: boolean;
  isMainNavOpen: boolean;
  pageType: string;
  windowLoaded: boolean;
}

export const initialState: GlobalState = {
  baseHref: "",
  inPageNav: [],
  filterCategory: null,
  pageId: null,
  pageTitle: "",
  businessUnit: "",
  youtubeScriptLoaded: false,
  trustPilot: { scriptLoaded: false, blockId: "", trustPilotData: {} },
  audienceNavigation: undefined,
  isMainNavMobileOpen: false,
  isMainNavOpen: false,
  pageType: "",
  windowLoaded: false
};

export const globalReducer = createReducer(
  initialState,
  on(setBaseHref, (state, { url }) => {
    return {
      ...state,
      baseHref: url
    };
  }),
  on(setInPageNavigation, (state, { item }) => {
    return {
      ...state,
      inPageNav: [...state.inPageNav, item]
    };
  }),
  on(resetInPageNavigation, (state) => {
    return {
      ...state,
      inPageNav: []
    };
  }),
  on(setFilterByCategory, (state, { category }) => {
    return {
      ...state,
      filterCategory: category
    };
  }),
  on(setCurrentPageId, (state, { pageId }) => {
    return {
      ...state,
      pageId: pageId
    };
  }),
  on(setCurrentPageTitle, (state, { pageTitle }) => {
    return {
      ...state,
      pageTitle: pageTitle
    };
  }),
  on(setCurrentBusinessUnit, (state, { businessUnit }) => {
    return {
      ...state,
      businessUnit: businessUnit
    };
  }),
  on(setYoutubeScriptLoaded, (state, { youtubeScriptLoaded }) => {
    return {
      ...state,
      youtubeScriptLoaded: youtubeScriptLoaded
    };
  }),
  on(setTrustPilot, (state, { scriptLoaded, blockId, trustPilotData }) => {
    return {
      ...state,
      trustPilot: { scriptLoaded, blockId, trustPilotData }
    };
  }),
  on(setAudienceNavigation, (state, { nav }) => {
    return {
      ...state,
      audienceNavigation: nav
    };
  }),
  on(setMainNavMobileOpen, (state, { open }) => {
    return {
      ...state,
      isMainNavMobileOpen: open
    };
  }),
  on(setMainNavOpen, (state, { open }) => {
    return {
      ...state,
      isMainNavOpen: open
    };
  }),
  on(setPageType, (state, { pageType }) => {
    return {
      ...state,
      pageType: pageType
    };
  }),
  on(setWindowLoaded, (state, { windowLoaded }) => {
    return {
      ...state,
      windowLoaded
    };
  })
);
