import { createAction, props } from "@ngrx/store";

export const setBaseHref = createAction(
  "[Global] Set current base href",
  props<{ url: string }>()
);

export const setInPageNavigation = createAction(
  "[Global] Set in page navigation items",
  props<{ item: any }>()
);

export const resetInPageNavigation = createAction(
  "[Global] Reset in page navigation items"
);

export const setFilterByCategory = createAction(
  "[Global] Set article filter category",
  props<{ category: number | null }>()
);

export const setCurrentPageId = createAction(
  "[Global] Set current page Id",
  props<{ pageId: number | null }>()
);

export const setCurrentPageTitle = createAction(
  "[Global] Set current page title",
  props<{ pageTitle: string }>()
);

export const setCurrentBusinessUnit = createAction(
  "[Global] Set current business unit",
  props<{ businessUnit: string }>()
);

export const setYoutubeScriptLoaded = createAction(
  "[Global] Set youtube API script status",
  props<{ youtubeScriptLoaded: boolean }>()
);

export const setTrustPilot = createAction(
  "[Global] Set Trustpilot Data script status",
  props<{ scriptLoaded: boolean; blockId: string; trustPilotData: any }>()
);

export const setAudienceNavigation = createAction(
  "[Global] Set audience navigation",
  props<{ nav: [any] }>()
);

export const setMainNavMobileOpen = createAction(
  "[Global] Set main nav mobile open",
  props<{ open: boolean }>()
);

export const setMainNavOpen = createAction(
  "[Global] Set main nav open",
  props<{ open: boolean }>()
);

export const setPageType = createAction(
  "[Global] Set page type",
  props<{ pageType: string }>()
);

export const setWindowLoaded = createAction(
  "[Global] Set window load was triggered",
  props<{ windowLoaded: boolean }>()
);
